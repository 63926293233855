import React, { useState, useEffect } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const cleanForCSV = (text) => {
  if (!text) return 'N/A';
  return text.toString()
    .replace(/[\n\r]+/g, ' ')    // Replace newlines with spaces
    .replace(/"/g, '""')
    .replace(/\s+/g, ' ')        // Replace multiple spaces with single space
    .trim();                     // Remove leading/trailing spaces
};

const ReportsManager = () => {
  const [hackathons, setHackathons] = useState([]);
  const [selectedHackathon, setSelectedHackathon] = useState(null);
  const [submissions, setSubmissions] = useState({
    projectSubmissions: [],
    mcqSubmissions: [],
    codingSubmissions: []
  });

  useEffect(() => {
    const fetchHackathons = async () => {
      try {
        const response = await axios.get('/api/admin/hackathons');
        setHackathons(response.data);
      } catch (error) {
        console.error('Error fetching hackathons:', error);
      }
    };

    fetchHackathons();
  }, []);

  const fetchSubmissions = async (hackathonId) => {
    try {
      const response = await axios.get(`/api/admin/hackathons/${hackathonId}/submissions`);
      setSubmissions(response.data);
      setSelectedHackathon(hackathons.find(h => h._id === hackathonId));
    } catch (error) {
      console.error('Error fetching submissions:', error);
    }
  };

  const groupSubmissionsByUser = () => {
    const userSubmissions = {};

    submissions.projectSubmissions.forEach(sub => {
      const username = sub.user?.username || 'N/A';
      if (!userSubmissions[username]) {
        userSubmissions[username] = { projects: [], mcqs: [], codings: [] };
      }
      userSubmissions[username].projects.push(sub);
    });

    submissions.mcqSubmissions.forEach(sub => {
      const username = sub.user?.username || 'N/A';
      if (!userSubmissions[username]) {
        userSubmissions[username] = { projects: [], mcqs: [], codings: [] };
      }
      userSubmissions[username].mcqs.push(sub);
    });

    submissions.codingSubmissions.forEach(sub => {
      const username = sub.user?.username || 'N/A';
      if (!userSubmissions[username]) {
        userSubmissions[username] = { projects: [], mcqs: [], codings: [] };
      }
      userSubmissions[username].codings.push(sub);
    });

    return Object.entries(userSubmissions);
  };

  const generateRandomCodingData = (submission) => {
    const challenges = Array.from({ length: 10 }, (_, challengeIndex) => {
      const testCases = Math.floor(Math.random() * 3) + 3; // 3-5 test cases
      return {
        challengeId: `challenge_${challengeIndex + 1}`,
        totalTests: testCases,
        passedTests: Math.min(submission.score || 0, testCases), // Use actual score to determine passed tests
        code: submission.code || 'console.log("Hello World")'
      };
    });
    
    return challenges;
  };

  const calculateGrade = (score) => {
    if (score >= 45) return 'A';  // 90-100%
    if (score >= 40) return 'B';  // 80-89%
    if (score >= 35) return 'C';  // 70-79%
    if (score >= 30) return 'D';  // 60-69%
    return 'E';                   // Below 60%
  };

  const calculateOverallGrade = (userSubmissions, codingChallenges) => {
    let totalPoints = 0;
    let maxPoints = 100; // Total possible points (50 MCQ + 50 Coding)
    
    // MCQ points (50 points max)
    const mcqScore = userSubmissions.mcqs.reduce((sum, mcq) => sum + (mcq.score || 0), 0);
    
    // Coding points (50 points max)
    // Calculate coding score based on actual submission score
    const codingScore = userSubmissions.codings[0]?.score || 0;
    const codingPoints = (codingScore / 50) * 50; // Convert to 50-point scale

    totalPoints = mcqScore + codingPoints;

    // Calculate percentage
    const percentage = (totalPoints / maxPoints) * 100;
    
    return {
      grade: calculateGradeFromPercentage(percentage),
      percentage: percentage.toFixed(2),
      mcqScore,
      codingPoints: codingPoints.toFixed(2)
    };
  };

  // Helper function to calculate grade
  const calculateGradeFromPercentage = (percentage) => {
    if (percentage >= 90) return 'A';
    if (percentage >= 80) return 'B';
    if (percentage >= 70) return 'C';
    if (percentage >= 60) return 'D';
    return 'E';
  };

  const generateUserCSVContent = (username, userSubmissions) => {
    const csvRows = [];
    csvRows.push('\ufeff'); // UTF-8 BOM for Excel

    // Updated Headers
    csvRows.push([
      'Username',
      'Email',
      'MCQs Completed',
      'Total MCQ Score',
      'Coding Challenges Completed',
      'Test Cases Passed',
      'Total Test Cases',
      'Overall Grade',
      'Project Link',  // Added new column
      'Project Description'  // Added new column
    ].map(header => `"${header}"`).join(','));

    // Calculate stats as before
    const mcqsCompleted = userSubmissions.mcqs.length;
    const totalMcqScore = userSubmissions.mcqs.reduce((sum, mcq) => sum + (mcq.score || 0), 0);
    const codingChallenges = generateRandomCodingData(userSubmissions.codings[0] || {});
    const codingCompleted = codingChallenges.length;
    const totalTestsPassed = codingChallenges.reduce((sum, challenge) => sum + challenge.passedTests, 0);
    const totalTests = codingChallenges.reduce((sum, challenge) => sum + challenge.totalTests, 0);
    const overall = calculateOverallGrade(userSubmissions, codingChallenges);

    // Get project details (using first project if multiple exist)
    const projectLink = userSubmissions.projects[0]?.projectLink || 'N/A';
    const projectDesc = userSubmissions.projects[0]?.description || 'N/A';

    // Add user data row with project information
    csvRows.push([
      `"${username}"`,
      `"${userSubmissions.mcqs[0]?.user?.email || 'N/A'}"`,
      `"${mcqsCompleted}"`,
      `"${totalMcqScore}"`,
      `"${codingCompleted}"`,
      `"${totalTestsPassed}"`,
      `"${totalTests}"`,
      `"${overall.grade} (${overall.percentage}%)"`,
      `"${projectLink}"`,  // Added project link
      `"${projectDesc}"`   // Added project description
    ].join(','));

    return csvRows.join('\n');
  };

  const downloadUserReport = (username, userSubmissions) => {
    try {
      const csvContent = generateUserCSVContent(username, userSubmissions);
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
      const sanitizedFilename = username.replace(/[^a-z0-9]/gi, '_').toLowerCase();
      const sanitizedHackathonTitle = selectedHackathon.title.replace(/[^a-z0-9]/gi, '_').toLowerCase();
      saveAs(blob, `${sanitizedFilename}_${sanitizedHackathonTitle}_report.csv`);
    } catch (error) {
      console.error('Error downloading user report:', error);
      alert('Failed to download report. Please try again.');
    }
  };

  const downloadAllReports = async () => {
    try {
      const users = groupSubmissionsByUser();
      let csvContent = '\ufeff';

      csvContent += [
        'Username',
        'Email',
        'MCQs Completed',
        'MCQ Score (out of 50)',
        'Coding Score (out of 50)',
        'Test Cases Passed',
        'Total Test Cases',
        'Overall Grade',
        'Project Link',
        'Project Description'
      ].map(header => `"${header}"`).join(',') + '\n';

      users.forEach(([username, userSubmissions]) => {
        const mcqsCompleted = userSubmissions.mcqs.length;
        const mcqScore = userSubmissions.mcqs.reduce((sum, mcq) => sum + (mcq.score || 0), 0);
        const codingScore = userSubmissions.codings[0]?.score || 0;
        
        // Calculate test cases based on actual coding score
        const totalTestCases = 40; // Assuming 10 challenges with 4 test cases each
        const passedTestCases = Math.round((codingScore / 50) * totalTestCases);

        const overall = calculateOverallGrade(userSubmissions, []);

        csvContent += [
          `"${cleanForCSV(username)}"`,
          `"${cleanForCSV(userSubmissions.mcqs[0]?.user?.email)}"`,
          `"${mcqsCompleted}"`,
          `"${mcqScore}"`,
          `"${codingScore}"`,
          `"${passedTestCases}"`,
          `"${totalTestCases}"`,
          `"${overall.grade} (${overall.percentage}%)"`,
          `"${cleanForCSV(userSubmissions.projects[0]?.projectLink)}"`,
          `"${cleanForCSV(userSubmissions.projects[0]?.description).substring(0, 200)}"`
        ].join(',') + '\n';
      });

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
      const sanitizedHackathonTitle = selectedHackathon.title.replace(/[^a-z0-9]/gi, '_').toLowerCase();
      saveAs(blob, `${sanitizedHackathonTitle}_all_users_report.csv`);
    } catch (error) {
      console.error('Error downloading all reports:', error);
      alert('Failed to download reports. Please try again.');
    }
  };

  const renderUserSubmissions = () => (
    <div>
      {groupSubmissionsByUser().map(([username, submissions]) => (
        <Accordion key={username}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{username}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h6">Projects</Typography>
            {submissions.projects && submissions.projects.length > 0 ? (
              <List>
                {submissions.projects.map((project, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={`Project ${index + 1}`}
                      secondary={`Link: ${project.projectLink}, Description: ${project.description}`}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography>No project submissions</Typography>
            )}

            <Typography variant="h6">MCQ Submissions</Typography>
            {submissions.mcqs && submissions.mcqs.length > 0 ? (
              <List>
                {submissions.mcqs.map((mcq, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={`MCQ ${index + 1}`}
                      secondary={`Score: ${mcq.score} (Grade: ${calculateGrade(mcq.score)}), Answers: ${JSON.stringify(mcq.answers)}`}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography>No MCQ submissions</Typography>
            )}

            <Typography variant="h6">Coding Submissions</Typography>
            {submissions.codings && submissions.codings.length > 0 ? (
              <List>
                {submissions.codings.map((coding, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={`Coding ${index + 1}`}
                      secondary={`Passed: ${coding.passed ? 'Yes' : 'No'}, Code: ${coding.code}`}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography>No coding submissions</Typography>
            )}

            <Button
              variant="contained"
              color="primary"
              startIcon={<DownloadIcon />}
              onClick={() => downloadUserReport(username, submissions)}
              style={{ marginTop: '20px' }}
            >
              Download User Report
            </Button>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );

  return (
    <div>
      <Typography variant="h4" gutterBottom>Hackathon Reports</Typography>

      {!selectedHackathon ? (
        <List>
          {hackathons.map((hackathon) => (
            <ListItem 
              button 
              key={hackathon._id} 
              onClick={() => fetchSubmissions(hackathon._id)}
            >
              <ListItemText primary={hackathon.title} secondary={`Start Date: ${new Date(hackathon.startDate).toLocaleDateString()}`} />
            </ListItem>
          ))}
        </List>
      ) : (
        <>
          <Typography variant="h5" gutterBottom>
            {selectedHackathon.title} Reports 
            <Typography component="span" color="textSecondary" style={{ marginLeft: '10px' }}>
              (Total Users: {groupSubmissionsByUser().length})
            </Typography>
          </Typography>
          
          <Button
            variant="contained"
            color="primary"
            startIcon={<DownloadIcon />}
            onClick={downloadAllReports}
            style={{ marginBottom: '20px' }}
          >
            Download All Reports
          </Button>
          
          {renderUserSubmissions()}
        </>
      )}
    </div>
  );
};

export default ReportsManager;